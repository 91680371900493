import axios from 'axios';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';

import { MotionHeaderBottom } from '@/components/layout/header/header-bottom';
import HeaderMid from '@/components/layout/header/header-mid/HeaderMid';
import { MotionHeaderTop } from '@/components/layout/header/header-top/HeaderTop';
import { OpenMobileApplicationBanner } from '@/components/layout/header/open-mobile-application-banner/OpenMobileApplicationBanner';
import { useHeaderScroll } from '@/components/layout/header/useHeaderScroll';

import { IS_ADMITAD_VISIT } from '@/helpers/condition/is-admitad-visit';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useHasMounted } from '@/hooks/useHasMounted';

import { loginUser, logoutUser, setUser } from '@/store/slices/login-user/login-user.slice';

import stl from './Header.module.scss';

function Header() {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const isCheckoutPage = router.asPath.includes('checkout');
  const { headerBottomHeight, headerBottomY } = useHeaderScroll();
  const hasMounted = useHasMounted();

  const loginChecker = useCallback(async () => {
    try {
      const { status, data } = await axios.get('/api/auth/me');
      if (status === 200) {
        dispatch(
          setUser({
            userId: data.id,
            userName: data.name,
            cashback: data.bonus_level.cashback,
            bonusCount: data.bonus_count,
          })
        );
        return dispatch(loginUser());
      }

      dispatch(logoutUser());
    } catch (e: any) {
      dispatch(logoutUser());
    }
  }, [loginUser, logoutUser]);

  useEffect(() => {
    loginChecker();
  }, [loginChecker]);

  return (
    <>
      <AnimatePresence>
        {hasMounted && !IS_ADMITAD_VISIT() && (
          <motion.div
            initial={{ height: 0 }}
            animate={{ height: 'auto' }}
            transition={{ delay: 1 }}
          >
            <OpenMobileApplicationBanner
              className={clsx(stl.mobileApplicationBanner, isCheckoutPage && stl.checkoutPage)}
            />
          </motion.div>
        )}
      </AnimatePresence>
      <motion.header className={stl.header}>
        <motion.div className={stl.container}>
          <MotionHeaderTop />
          <HeaderMid />
          <MotionHeaderBottom style={{ height: headerBottomHeight, y: headerBottomY }} />
        </motion.div>
      </motion.header>
    </>
  );
}
export default Header;
