import clsx from 'clsx';
import { FC, HTMLAttributes } from 'react';

import { Container } from '@/components/container';
import { Image } from '@/components/image';
import { menus, mockLists } from '@/components/layout/footer/footer.data';
import { PaymentParts } from '@/components/layout/footer/payment-parts/PaymentParts';

import { useHasMounted } from '@/hooks/useHasMounted';
import { useMediaQuery } from '@/hooks/useMediaQuery';

import styles from './Footer.module.scss';
import { BottomBar } from './bottom-bar/BottomBar';
import { MenuList } from './menu-list/MenuList';
import { NavigationList } from './navigation-list/NavigationList';
import { PaymentMethods } from './payment-methods/PaymentMethods';
import { SocialBlock } from './social-block/SocialBlock';

const Footer: FC<HTMLAttributes<HTMLDivElement>> = ({ className }) => {
  const isMobileDevice = useMediaQuery('768px');

  return (
    <footer className={clsx(className)}>
      <Container variant="footer">
        <div className={styles.mainGrid}>
          <Image
            className={styles.logo}
            src={'/static/logo/azalianow-logo-red.svg'}
            priority
            width={184}
            height={84}
            alt="Логотип AzaliaNow"
          />
          <div className={styles.menus}>
            {menus.map((menu) => (
              <MenuList
                className={styles.menuBlock}
                key={menu.title}
                title={menu.title}
                items={menu.items}
                mode={isMobileDevice ? 'accordion' : 'default'}
              />
            ))}
            <PaymentMethods className={clsx(styles.paymentMethodsBlock, styles.desktop)} />
            <PaymentParts className={clsx(styles.paymentPartsBlock, styles.desktop)} />
          </div>

          <SocialBlock className={styles.social} />
        </div>
      </Container>

      <Container variant="footer" className={styles.navigationLists}>
        <div className={styles.navigationGrid}>
          {mockLists.map((list) => (
            <NavigationList
              key={list.title}
              href={list.href}
              title={list.title}
              items={list.items}
              mode={isMobileDevice && list.collapsable ? 'accordion' : 'default'}
            />
          ))}
        </div>

        {/*<NavigationList*/}
        {/*  variant="column"*/}
        {/*  key={mockFlowerLarge.title}*/}
        {/*  title={mockFlowerLarge.title}*/}
        {/*  items={mockFlowerLarge.items}*/}
        {/*  mode={isMobileDevice && mockFlowerLarge.collapsable ? 'accordion' : 'default'}*/}
        {/*/>*/}
      </Container>

      <Container variant="footer">
        <PaymentMethods
          isMobile={isMobileDevice}
          className={clsx(styles.paymentMethodsBlock, styles.mobile)}
        />
        <PaymentParts className={clsx(styles.paymentPartsBlock, styles.mobile)} />
      </Container>

      <Container variant="footer" className={styles.bottomInformation}>
        <BottomBar />
      </Container>
    </footer>
  );
};
export default Footer;
