import { getCookie } from 'cookies-next';
import { GetServerSidePropsContext } from 'next';

export const IS_ADMITAD_VISIT = (options?: {
  req: GetServerSidePropsContext['req'];
  res: GetServerSidePropsContext['res'];
}) => {
  const isAdmitadUtm =
    getCookie('utm_source', {
      ...options,
    }) === 'admitad';

  const isAdmitadUidExist = !!getCookie('admitad_uid', {
    ...options,
  });

  return isAdmitadUtm && isAdmitadUidExist;
};
