import clsx from 'clsx';
import { motion } from 'framer-motion';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { MouseEvent, forwardRef, useCallback, useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Image } from '@/components/image';
import HeaderCities from '@/components/layout/header/header-cities/HeaderCities';
import { ArrowIcon, QrIcon } from '@/components/layout/icons/other';
import { Skeleton } from '@/components/ui/loaders/Skeletons/Skeletons';

import { IS_ADMITAD_VISIT } from '@/helpers/condition/is-admitad-visit';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useHasMounted } from '@/hooks/useHasMounted';
import { useMediaQuery } from '@/hooks/useMediaQuery';

import { useGetHeaderMenuQuery } from '@/store/api/header-menu.api';
import { toggleModal } from '@/store/slices/mobile-applicationn-qr-modal/mobile-application-qr-modal';

import { THeaderMenuItem } from '@/types/new/header-menu';

import stl from './HeaderBottom.module.scss';

const ModalWrapper = dynamic(() => import('@/components/ui/modals/modal-wrapper/ModalWrapper'));

const DropdownList = dynamic(() => import('./dropdown-list/DropdownList'));

export interface IDropdownData {
  title: string;
  items: THeaderMenuItem['items'];
  coords: { x: number; y: number };
}

// eslint-disable-next-line react/display-name
const HeaderBottom = forwardRef<HTMLDivElement, { className?: string }>(({ className }, ref) => {
  const { data, isLoading } = useGetHeaderMenuQuery();
  const isTablet = useMediaQuery('770px', 'max-width');
  const [dropdown, setDropdown] = useState<IDropdownData | null>(null);
  const [swiperBlur, setSwiperBlur] = useState(true);
  const dispatch = useAppDispatch();
  const hasMounted = useHasMounted();

  const onShowDropdown = (
    e: MouseEvent<HTMLElement>,
    items: THeaderMenuItem['items'],
    title: string
  ) => {
    const coords = (e.target as HTMLElement).getBoundingClientRect();
    const showDropdown = () => setDropdown({ coords, items, title });

    if (!dropdown) {
      showDropdown();
    }
  };

  const onCloseDropdown = useCallback(() => {
    setDropdown(null);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', onCloseDropdown);
    return () => window.removeEventListener('scroll', onCloseDropdown);
  }, [onCloseDropdown]);

  return (
    <>
      <div className={className} ref={ref} onMouseLeave={onCloseDropdown}>
        <div className={stl.container}>
          {isTablet && (
            <ModalWrapper isOpen={Boolean(dropdown)} onClose={onCloseDropdown}>
              <div className={stl.popup}>
                <p className={stl.popup_title}>{dropdown?.title}</p>
                {dropdown && <DropdownList dropdown={dropdown} />}
              </div>
            </ModalWrapper>
          )}
          <Swiper
            slidesPerView="auto"
            watchSlidesProgress
            draggable
            className={stl.hits}
            onTouchMove={onCloseDropdown}
            onSlideChange={(swiper) => {
              if (swiper.progress >= 0.9) {
                setSwiperBlur(false);
              } else {
                setSwiperBlur(true);
              }
            }}
          >
            {isLoading && (
              <div className={stl.skeletonWrapper}>
                {[...new Array(7)].map((_, i) => (
                  <Skeleton key={i} className={stl.skeleton} />
                ))}
              </div>
            )}
            {swiperBlur && <div className={stl.hits_shadow} />}
            {data?.map((el, i) => {
              const hasItems = el.items;
              const Component = hasItems ? 'button' : Link;
              const componentProps: any = hasItems ? { type: 'button' } : { href: el.href };

              return (
                <SwiperSlide
                  key={`${el.href}-${i}`}
                  className={stl.hits_item_slide}
                  onMouseOver={(e) => e.preventDefault()}
                  onMouseEnter={(e) => {
                    onCloseDropdown();
                    return hasItems && onShowDropdown(e, el.items, el.title);
                  }}
                  onClick={(e) => hasItems && onShowDropdown(e, el.items, el.title)}
                >
                  <Component
                    {...componentProps}
                    id={el.title}
                    className={clsx(stl.hits_item, dropdown?.title === el.title && stl.open)}
                  >
                    <p className={stl.hits_title}>{el.title}</p>
                    {el.icon && (
                      <Image
                        src={el.icon}
                        width={16}
                        height={16}
                        alt={el.title}
                        className={stl.hits_icon}
                      />
                    )}
                    {hasItems && <ArrowIcon />}
                  </Component>
                </SwiperSlide>
              );
            })}
          </Swiper>
          <div className={stl.right}>
            <button
              type="button"
              className={stl.app}
              style={{ visibility: hasMounted && !IS_ADMITAD_VISIT() ? 'visible' : 'hidden' }}
              onClick={() => dispatch(toggleModal())}
            >
              <span className={stl.desktop}>Мобильное приложение</span>
              <span className={stl.mobile}>Приложение</span>
              <QrIcon />
            </button>
            {/* //TODO переработать города, когда будет флоу от дизайнеров */}
            <HeaderCities />
          </div>
        </div>
      </div>
      {!isTablet && dropdown && (
        <div
          className={stl.dropdown_wrapper}
          style={{ top: dropdown.coords.y + 43, left: dropdown.coords.x + 8 }}
          onMouseOver={(e) => e.preventDefault()}
          onMouseEnter={() => setDropdown(dropdown)}
          onMouseLeave={(e) =>
            (e.relatedTarget as HTMLElement)?.id !== dropdown.title && onCloseDropdown()
          }
        >
          <div className={stl.dropdown}>
            <DropdownList dropdown={dropdown} />
          </div>
        </div>
      )}
    </>
  );
});

export const MotionHeaderBottom = motion(HeaderBottom);

export default HeaderBottom;
